<template>
  <div class="container">
    <div class="handle-box">
      <el-button icon="el-icon-refresh" @click="onRefresh">刷新</el-button>
      <el-button type="success" icon="el-icon-plus" @click="onAdd">
        添加
      </el-button>
      <el-button type="danger" icon="el-icon-delete" @click="delAll">
        批量删除
      </el-button>
    </div>
    <!--数据表格-->
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
      @row-dblclick="onEdit"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column label="地名" prop="name" />
      <el-table-column label="详细地址" prop="address" />
      <el-table-column label="手机号码" prop="telephone" />
      <el-table-column label="固定号码" prop="landphone" />
      <el-table-column label="联系人" prop="linkman" />
      <el-table-column label="备注描述" prop="description" />
      <el-table-column label="标间总数/剩余" align="center">
        <template slot-scope="scope">
          {{ scope.row.standard }}/{{ scope.row.lessStandard }}
        </template>
      </el-table-column>
      <el-table-column label="套房总数/剩余" align="center">
        <template slot-scope="scope">
          {{ scope.row.suite }}/{{ scope.row.lessSuite }}
        </template>
      </el-table-column>
      <el-table-column label="是否开放" prop="isOpen" align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isOpen"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwitch($event, scope.row, scope.$index)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="280" align="center">
        <template slot-scope="scope">
          <el-button type="success" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- 编辑信息 -->
    <el-dialog title="编辑信息" width="50%" :visible.sync="selectDlgShow">
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="ruleForm" :model="form" label-width="120px">
          <el-form-item label="地名" prop="name" required>
            <el-input
              v-model="form.name"
              type="text"
              placeholder="请输入地名"
              clearable
            />
          </el-form-item>
          <el-form-item label="详细地址" prop="address" required>
            <el-input
              v-model="form.address"
              type="text"
              placeholder="请输入详细地址"
              clearable
            />
          </el-form-item>
          <el-form-item label="手机号码" prop="telephone" required>
            <el-input
              v-model="form.telephone"
              type="text"
              placeholder="请输入手机号码"
              clearable
            />
          </el-form-item>
          <el-form-item label="固定号码" prop="landphone">
            <el-input
              v-model="form.landphone"
              type="type"
              placeholder="请输入固定号码"
              clearable
            />
          </el-form-item>
          <el-form-item label="联系人" prop="linkman" required>
            <el-input
              v-model="form.linkman"
              type="type"
              placeholder="请输入联系人"
              clearable
            />
          </el-form-item>
          <el-form-item label="备注描述" prop="description">
            <el-input
              v-model="form.description"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入内容"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="标间数量" prop="standard" required>
            <el-input-number
              v-model="form.standard"
              :min="0"
              :max="200"
              clearable
            ></el-input-number>
          </el-form-item>
          <el-form-item label="套房数量" prop="suite" required>
            <el-input-number
              v-model="form.suite"
              :min="0"
              :max="200"
              clearable
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="selectDlgShow = false">关 闭</el-button>
        <el-button plain @click="resetForm('ruleForm')">重 置</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  saveRoom,
  getRoomList,
  delRoom,
  getLessRoomNums
} from '@/api/sleep/room';

export default {
  name: 'SleepRoom',
  data() {
    return {
      form: {
        id: '',
        name: '',
        address: '',
        telephone: '',
        landphone: '',
        linkman: '',
        description: '',
        standard: 0,
        suite: 0,
        isOpen: 1
      },
      query: {
        name: '',
        datearray: '',
        status: ''
      },
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0
      },
      list: [],
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onRefresh() {
      this.fetchData();
    },
    //添加
    onAdd() {
      this.form = {
        id: '',
        name: '',
        address: '',
        telephone: '',
        landphone: '',
        linkman: '',
        description: '',
        standard: 0,
        suite: 0,
        isOpen: 1
      };
      this.selectDlgShow = true;
    },
    //编辑
    onEdit(row) {
      this.form = {
        id: row.id,
        name: row.name,
        address: row.address,
        telephone: row.telephone,
        landphone: row.landphone,
        linkman: row.linkman,
        description: row.description,
        standard: row.standard,
        suite: row.suite,
        isOpen: row.isOpen
      };
      this.selectDlgShow = true;
    },
    //查询列表
    fetchData() {
      this.listLoading = true;
      getRoomList(this.pageInfo).then(response => {
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    //获取剩余数量
    fetchLessRoomNums(roomId) {
      getLessRoomNums({ roomId: roomId }).then(() => {
        this.le;
      });
    },
    //批量删除
    delAll() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning('请至少选择一条数据');
        return;
      }
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delRoom({ ids: ids })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //删除
    onDel(data) {
      this.$confirm('您确定要删除此条数据吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delRoom({ id: data.id })
          .then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.fetchData();
          })
          .catch(() => {
            this.$message.error('删除错误！');
          });
      });
    },
    //验证表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    //保存与修改
    save() {
      this.dlgLoading = true;
      saveRoom(this.form)
        .then(resp => {
          if (resp.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            });
            this.selectDlgShow = false;
            this.dlgLoading = false;
            this.fetchData();
          } else {
            this.$message.error('保存失败');
          }
        })
        .catch(() => {
          this.dlgLoading = false;
        });
    },
    //开关切换
    changeSwitch(data, b) {
      saveRoom(b).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>
<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
</style>
