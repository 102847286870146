import request from '@/utils/request';

/**
 * 保存或修改预约地址信息
 * @param {Object} data
 */
export function saveRoom(data) {
  return request({
    url: '/sleep/manager/saveRoom',
    method: 'post',
    data
  });
}

/**
 * 查询列表
 * @param {Object} data
 */
export function getRoomList(data) {
  return request({
    url: '/sleep/manager/getRoomList',
    method: 'post',
    data
  });
}

/**
 * 删除操作
 * @param {Object} data
 */
export function delRoom(data) {
  return request({
    url: '/sleep/manager/delRoom',
    method: 'post',
    data
  });
}

/**
 * 获取房间剩余数量
 * @param {Object} data
 */
export function getLessRoomNums(data) {
  return request({
    url: '/sleep/appointment/getLessRoomNums',
    method: 'post',
    data
  });
}
